<template>

  <div>
    <b-card>
      <b-tabs>
        <!-- Tab: Boletim -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Boletim de Caixa</span>
          </template>

          <cash-bulletin
            ref="cashBulletinTab"
            @refetchData="refetchPositionData"
            @refetchDataTransfer="refetchTransferData"
          />

        </b-tab>

        <!-- Tab: Transferências -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Transferências</span>
          </template>

          <transfer
            ref="transferTab"
          />

        </b-tab>

        <!-- Tab: Posição -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="EditIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Posição de Caixa</span>
          </template>

          <position
            ref="positionTab"
            @refetchAccounts="refetchAccounts"
          />

        </b-tab>

      </b-tabs>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import cashBulletin from './tabs/cash-bulletin/CashBulletin.vue'
import transfer from './tabs/transfer/Transfer.vue'
import position from './tabs/position/Position.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

    cashBulletin,
    transfer,
    position,
  },
  setup() {
    const positionTab = ref(null)
    const transferTab = ref(null)
    const cashBulletinTab = ref(null)

    const refetchPositionData = () => {
      positionTab.value.refTable.refresh()
    }

    const refetchTransferData = () => {
      transferTab.value.refTable.refresh()
      refetchPositionData()
    }

    const refetchAccounts = () => {
      cashBulletinTab.value.fetchAccounts()
    }

    return {
      positionTab,
      transferTab,
      cashBulletinTab,
      refetchPositionData,
      refetchTransferData,
      refetchAccounts,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
