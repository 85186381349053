import { ref, watch, computed } from '@vue/composition-api'
import { dateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useTransfer() {
  // Use toast
  const toast = useToast()

  const cashierState = ref(false)

  const refTable = ref(null)
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const showOverlay = ref(false)

  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const formatDate = dte => `${dte.getFullYear()}-${(dte.getMonth() + 1) < 10 ? `0${dte.getMonth() + 1}` : (dte.getMonth() + 1)}-${dte.getDate() < 10 ? `0${dte.getDate()}` : dte.getDate()}`

  const filterStartDate = ref(formatDate(firstDay))
  const filterEndDate = ref(formatDate(lastDay))

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const tableColumns = [
    {
      key: 'created_at',
      label: 'Data',
      formatter: value => dateTime(value, 'dateTimeNoSecond'),
      tdClass: 'created_atTdClass',
      sortable: true,
    },
    {
      key: 'responsible.name',
      label: 'Responsável',
      tdClass: 'idTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'descrição',
      tdClass: 'descriptionTdClass',
      sortable: true,
    },
    {
      key: 'cashIn',
      label: 'Entrada',
      tdClass: 'cashInTdClass',
      sortable: true,
    },
    {
      key: 'cashOut',
      label: 'Saída',
      tdClass: 'cashOutTdClass',
      sortable: true,
    },
  ]

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const handleAccounts = accounts => accounts.map(e => ({
    account: e.account,
    details: JSON.parse(e.pivot.details),
  }))

  const handleDescription = description => description.split('<br>')

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-cash-flow/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        type: ['TRANSFER'],
      })
      .then(response => {
        showOverlay.value = false
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    cashierState,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTable,
    filterStartDate,
    filterEndDate,
    showOverlay,

    fetch,
    refetchData,
    handleAccounts,
    handleDescription,
  }
}
