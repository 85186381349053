<template>
  <b-modal
    id="modal-transfer"
    v-model="showLocal"
    centered
    size="lg"
    title="Detalhes"
    hide-footer
    @hidden="hidden"
  >
    {{ movement }}

  </b-modal>
</template>

<script>
import {
  ref, toRefs, watch,
} from '@vue/composition-api'

export default {
  components: {
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    movement: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    const { show } = toRefs(props)

    watch(show, newValue => {
      showLocal.value = newValue
    })

    const hidden = () => {
      emit('hidden')
    }

    return {
      showLocal,

      hidden,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
