import { ref, watch, computed } from '@vue/composition-api'
import { currency } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function usePosition(root, emit) {
  // Use toast
  const toast = useToast()

  const accountShowModal = ref(false)
  const refTable = ref(null)
  const showOverlay = ref(false)

  const generalCashier = computed(() => store.state['store-general-cashier'].generalCashier)

  // Table Handlers
  const tableColumns = [
    {
      key: 'account',
      label: 'conta',
      tdClass: 'accountTdClass',
      sortable: true,
    },
    {
      key: 'previous_balance',
      label: 'saldo anterior',
      tdClass: 'previousBalanceTdClass',
      sortable: true,
    },
    {
      key: 'cashIn',
      label: 'entradas',
      formatter: currency,
      tdClass: 'valueInstallmentTdClass',
      sortable: true,
    },
    {
      key: 'cashOut',
      label: 'saídas',
      formatter: currency,
      tdClass: 'valueInstallmentTdClass',
      sortable: true,
    },
    {
      key: 'balance',
      label: 'saldo atual',
      formatter: currency,
      tdClass: 'balanceTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const hideWwithoutMovementBalance = ref(false)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const accountFilter = ref(null)
  const editAccount = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const accountTypes = ref([
    { label: 'SIMPLES', value: 'SIMPLE' },
    { label: 'BANCO', value: 'BANK' },
    { label: 'CARTÃO', value: 'CARD' },
  ])

  const showsInOptions = ref([
    { label: 'A PAGAR', value: 'TO_PAY' },
    { label: 'A RECEBER', value: 'TO_RECEIVE' },
    { label: 'FLUXO DE CAIXA', value: 'CASH_FLOW' },
    { label: 'TRANSFERÊNCIA', value: 'TO_TRANSFER' },
  ])

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, hideWwithoutMovementBalance, accountFilter], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-account/fetch', {
        q: searchQuery.value,
        hideWwithoutMovementBalance: hideWwithoutMovementBalance.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        accountFilter: accountFilter.velue,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        withTrashed: true,
      })
      .then(response => {
        showOverlay.value = false
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        showOverlay.value = false
        catchErr(e)
      })
  }

  const save = async data => {
    try {
      const payload = JSON.parse(JSON.stringify(data))
      if (payload.type !== 'SIMPLE' && payload.bank_account && !payload.bank_account.bank_id) payload.bank_account.bank_id = payload.bank_account.bank.value
      if (payload.id) await store.dispatch('store-account/update', payload)
      else await store.dispatch('store-account/add', payload)
      refetchData()
      emit('refetchAccounts')
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const newAccount = () => {
    editAccount.value = null
    accountShowModal.value = true
  }

  const handleEdit = data => {
    editAccount.value = JSON.parse(JSON.stringify(data))
    accountShowModal.value = true
  }

  const inactivateAccount = async account => {
    try {
      await store.dispatch('store-account/delete', account)
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  const reactiveAccount = async account => {
    try {
      await store.dispatch('store-account/reactive', account)
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  const handleReactive = account => {
    root.$swal({
      title: 'Tem certeza?',
      text: 'Deseja reativar o registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        await reactiveAccount(account)
        root.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'O registro foi reativado.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    })
  }

  const handleInactivate = account => {
    root.$swal({
      title: 'Tem certeza?',
      text: 'Deseja inativar o registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        await inactivateAccount(account)
        root.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'O registro foi inativado.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    })
  }

  const handleOpeningPosition = account => {
    const openingPosition = generalCashier.value ? generalCashier.value.opening_position.find(e => e.account === account) : null
    if (!openingPosition) return 'R$ 0,00'
    return openingPosition.balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  const handleAccountFlow = (account, direction) => {
    if (generalCashier.value && generalCashier.value.cash_flows) {
      const accounts = generalCashier.value.cash_flows.filter(e => e.direction === direction && e.accounts.some(e2 => e2.account === account)).map(e => e.accounts).flat()

      const accountsFiltered = accounts.filter(e => e.account === account)
      if (accountsFiltered.length) {
        return accountsFiltered.map(e => e.pivot.amount).reduce((prev, curr) => prev + curr, 0)
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    }
    return 'R$ 0,00'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    hideWwithoutMovementBalance,
    sortBy,
    isSortDirDesc,
    refTable,
    accountTypes,
    showsInOptions,
    accountShowModal,
    editAccount,
    generalCashier,
    showOverlay,

    fetch,
    refetchData,
    save,
    newAccount,
    handleEdit,
    handleInactivate,
    handleReactive,
    handleOpeningPosition,
    handleAccountFlow,
  }
}
