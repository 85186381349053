<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="modal-transfer"
      v-model="showLocal"
      centered
      size="lg"
      title="Transferência"
      hide-footer
      @hidden="hidden"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :rules="'required'"
              name="Data"
              immediate
            >
              <b-form-group
                label="Data"
                label-for="transfer-date"
              >
                <b-input-group>
                  <b-form-input
                    id="transfer-date"
                    v-model="transfer.date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="De"
              rules="required"
              immediate
            >
              <b-form-group
                label="De:"
                label-for="from"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="transfer.from"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="false"
                  :options="accountsLocal"
                  placeholder="Conta de saída"
                  input-id="from"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Para"
              rules="required"
              immediate
            >
              <b-form-group
                label="Para:"
                label-for="to"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="transfer.to"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="false"
                  :options="accountsLocal"
                  placeholder="Conta de entrada"
                  input-id="to"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <b-form-group
              label="Observação"
              label-for="observations"
            >
              <b-form-input
                id="observations"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Valor"
              label-for="transfer-value"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span>R$</span>
                </b-input-group-prepend>
                <currency-input
                  v-model="transfer.transferAmount"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <!-- Footer -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          />

          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="outline-secondary"
              block
              @click="hidden"
            >
              Cancelar
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              :disabled="invalid"
              @click="saveTransfer"
            >
              Transferir
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
  BInputGroup, BInputGroupPrepend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BButton,

    ValidationProvider,
    ValidationObserver,
    CurrencyInput,
    vSelect,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages
    const showLocal = ref(false)
    const accountsLocal = ref([])
    const { show, accounts } = toRefs(props)
    const transfer = ref({})

    const blankData = {
      date: new Date().toISOString().split('T')[0],
      from: null,
      to: null,
      transferAmount: 0,
    }

    watch(show, newValue => {
      const accountsClone = JSON.parse(JSON.stringify(accounts.value))
      accountsLocal.value = accountsClone.map(e => ({
        value: e.id,
        label: e.account,
        data: e,
      }))

      transfer.value = JSON.parse(JSON.stringify(blankData))
      showLocal.value = newValue
    })

    const hidden = () => {
      accountsLocal.value = []
      emit('hidden')
    }

    const saveTransfer = async () => {
      emit('save', transfer.value)
      if (transfer.value.to !== transfer.value.from) hidden()
    }

    return {
      showLocal,
      transfer,
      accountsLocal,

      saveTransfer,
      hidden,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
