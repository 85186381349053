import axios from '@axios'

export default {
  namespaced: true,
  state: {
    flags: [],
  },

  getters: {
    getToSelect: state => state.flags.map(s => ({ value: s.id, label: s.flag })),
  },

  mutations: {
    SET(state, flags) {
      state.flags = flags
    },
  },

  actions: {
    async fetch({ state, commit }) {
      if (!state.flags.length) {
        try {
          const flags = (await axios.get(`${process.env.VUE_APP_API_URL}/card-flags`)).data
          commit('SET', flags)
        } catch (e) {
          return e
        }
      }
      return state.flags
    },
  },
}
