<template>
  <div>
    <handle-modal
      ref="refHandleModal"
      :show="accountShowModal"
      :edit-account="editAccount"
      @save="save"
      @hidden="accountShowModal = false; editAccount = null"
    />

    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="mb-2">

        <b-card-header
          style="font-size: 18px; font-weight: bold;"
        >
          <b-card-title>
            <span>Posição do caixa</span>
          </b-card-title>
        </b-card-header>

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-checkbox
              v-model="hideWwithoutMovementBalance"
              class="custom-control-primary mb-0.5"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckCircleIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XCircleIcon" />
              </span>
            </b-form-checkbox>
            <small>
              Ocultar contas sem saldo
            </small>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end mt-1">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              block
              class="mt-1"
              @click="newAccount"
            >
              <span>Criar Conta</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        no-fade
      >
        <b-table
          ref="refTable"
          class="position-relative tableMinHeght"
          :items="fetch"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          :tbody-tr-class="rowClass"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Description-->
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template #cell(previous_balance)="data">
            {{ handleOpeningPosition(data.item.account) }}
          </template>

          <!-- Column: Cash In -->
          <template #cell(cashIn)="data">
            {{ handleAccountFlow(data.item.account, 'IN') }}
          </template>

          <!-- Column: Cash Out -->
          <template #cell(cashOut)="data">
            {{ handleAccountFlow(data.item.account, 'OUT') }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="handleEdit(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="handleInactivate(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Inativar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="handleReactive(data.item)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span
                  class="align-middle ml-50"
                >Reativar</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BCardHeader, BRow, BCol, BFormInput, BButton, BFormCheckbox, BTable, BPagination, BDropdown, BDropdownItem, BCardTitle, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useStoreAccount from '@/views/apps/finances/general-cashier/tabs/position/positionStoreModule'
import usePosition from './usePosition'
import HandleModal from './HandleModal.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
    HandleModal,
    BOverlay,
  },

  setup(props, { root, emit }) {
    const USER_APP_STORE_MODULE_NAME = 'store-account'

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      return null
    }

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, useStoreAccount)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      refTable,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      hideWwithoutMovementBalance,
      sortBy,
      isSortDirDesc,
      accountShowModal,
      editAccount,
      generalCashier,
      showOverlay,

      save,
      fetch,
      newAccount,
      handleEdit,
      handleInactivate,
      handleReactive,
      handleOpeningPosition,
      handleAccountFlow,
    } = usePosition(root, emit)

    return {
      refTable,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      hideWwithoutMovementBalance,
      sortBy,
      isSortDirDesc,
      accountShowModal,
      editAccount,
      generalCashier,
      showOverlay,

      save,
      fetch,
      newAccount,
      handleEdit,
      handleInactivate,
      handleReactive,
      rowClass,
      handleOpeningPosition,
      handleAccountFlow,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
      // resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>
