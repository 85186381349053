// import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    generalCashier: null,
  },
  getters: {
    getGeneralCashier(state) {
      return state.generalCashier || {}
    },
  },

  mutations: {
    SET(state, data) {
      state.generalCashier = data
    },
    DELETE(state) {
      state.generalCashier = { }
    },
  },

  actions: {
    async getLast({ commit }) {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cashier/get-last-general`)
        commit('SET', resp.data)
        return resp
      } catch (e) {
        if (e.response.status === 404) commit('SET', null)
        return e
      }
    },

    async handleStatus({ commit }, status) {
      const payload = {
        status,
        is_general_cashier: true,
      }

      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/cashier/handle/status`, payload)
          .then(resp => {
            commit('SET', resp.data)
            return resolve(resp)
          })
          .catch(e => {
            if (e.response.status === 404) commit('SET', null)
            return reject(e)
          })
      })
    },

    saveTransfer({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/cashiers/transfer`, data)
          .then(response => {
            dispatch('getLast')
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    // async fetch({ state, commit }, args) {
    //   try {
    //     if (!state.accounts.length || args) {
    //       const resp = await axios.get(`${process.env.VUE_APP_API_URL}/accounts${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
    //       if (!args) commit('SET_LIST', resp.data)
    //       else { commit('SET_LIST', resp.data) }
    //       return resp
    //     }
    //     return state.accounts
    //   } catch (e) {
    //     return e
    //   }
    // },

    // fetchOne({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${process.env.VUE_APP_API_URL}/accounts/${id}`)
    //       .then(response => {
    //         commit('SET', response.data)
    //         commit('UPDATE_IN_LIST', response.data)
    //         return resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    // add({ commit }, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`${process.env.VUE_APP_API_URL}/account`, data)
    //       .then(response => {
    //         commit('SET', response.data)
    //         commit('ADD_IN_LIST', response.data)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    // update({ commit }, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .put(`${process.env.VUE_APP_API_URL}/account/${data.id}`, data)
    //       .then(response => {
    //         commit('SET', response.data)
    //         commit('UPDATE_IN_LIST', response.data)
    //         return resolve(response)
    //       })
    //       .catch(e => {
    //         reject(e)
    //       })
    //   })
    // },

    // delete({ commit }, data) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete(`${process.env.VUE_APP_API_URL}/account/${data.id}`)
    //       .then(response => {
    //         commit('DELETE_REGISTER', data.id)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
