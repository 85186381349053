<template>
  <b-modal
    id="close-confirm-modal"
    v-model="closeConfirmModalShow"
    centered
    size="lg"
    title="Confirme o fechamento de caixa!"
    @hidden="hidden"
  >

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-row class="pl-2 pr-2 pt-2">

        <!-- Field: Code -->
        <b-col
          cols="12"
          md="2"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            size="30"
            class="text-warning stroke-current"
          />
        </b-col>

        <!-- Field: Procedure -->
        <b-col
          cols="12"
          md="10"
          class="pb-2"
        >
          <span>Atenção: Confira seu saldo e não esqueça de efetuar todas as transferências necessárias antes de fechar o caixa!</span>
        </b-col>

      </b-row>

    </b-card>
    <template #modal-footer="{ cancel }">
      <b-form-group
        label="Gerar relatório"
        label-for="switch-print-report"
        class="mt-0 d-flex justify-content-center"
      >
        <b-form-checkbox
          id="switch-print-report"
          v-model="printReport"
          class="ml-1 d-flex justify-content-center"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckCircleIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XCircleIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>

      <b-button
        variant="primary"
        @click="$emit('closeCashier', printReport); closeConfirmModalShow = false"
      >
        Fechar caixa
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormGroup,
    BFormCheckbox,
  },

  setup() {
    const closeConfirmModalShow = ref(false)
    const printReport = ref(false)

    const hidden = () => {
      printReport.value = false
    }

    return {
      required,
      closeConfirmModalShow,
      printReport,
      hidden,
    }
  },
}
</script>
