<template>
  <b-card
    no-body
    class="mb-0 mt-2"
  >

    <div class="mb-2">

      <b-card-header
        style="font-size: 18px; font-weight: bold;"
      >
        <b-card-title>
          <span>Transferência de Valores</span>
        </b-card-title>
      </b-card-header>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="De"
            label-for="transfer-start-date"
          >
            <b-input-group>
              <b-form-input
                id="transfer-start-date"
                v-model="filterStartDate"
                type="date"
                trim
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Até"
            label-for="transfer-end-date"
          >
            <b-input-group>
              <b-form-input
                id="transfer-end-date"
                v-model="filterEndDate"
                type="date"
                trim
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <!--  -->
        </b-col>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
        >
          <label>Exibir</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>resultados</label>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="9"
        >
          <div class="d-flex align-items-center justify-content-end mt-1">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-overlay
      :show="showOverlay"
      rounded="sm"
      no-fade
    >
      <b-table
        ref="refTable"
        class="position-relative tableMinHeght"
        :items="fetch"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(description)="data">
          <span
            v-for="(description, idx) in handleDescription(data.item.description)"
            :key="idx"
          >
            {{ description }} <br>
          </span>
        </template>

        <template #cell(accounts)="data">
          <span
            v-for="(account, idx) in handleAccounts(data.item.accounts)"
            :key="idx"
          >
            {{ account.account }} <br>
            <span
              v-for="(details, idx2) in account.details"
              :key="idx2"
            >
              <br v-if="idx2 > 0">
              {{ details.paymentMethod }} | {{ details.paymentValue ? details.paymentValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
            </span>
          </span>
        </template>

        <!-- Column: Cash In -->
        <template #cell(cashIn)="data">
          {{ data.item.direction === 'IN' ? data.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
        </template>

        <!-- Column: Cash Out -->
        <template #cell(cashOut)="data">
          {{ data.item.direction === 'OUT' ? data.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
        </template>

      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalData"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>

import {
  BCard, BCardHeader, BRow, BCol, BFormGroup, BInputGroup, BFormInput,
  BTable, BPagination, BCardTitle, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useTransfer from './useTransfer'
import cashFlowStoreModule from '../../cashFlowStoreModule'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BRow,
    BInputGroup,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BOverlay,
    vSelect,
  },

  setup() {
    const STORE_MODULE_CASH_FLOW = 'store-cash-flow'

    // Register module
    if (!store.hasModule(STORE_MODULE_CASH_FLOW)) store.registerModule(STORE_MODULE_CASH_FLOW, cashFlowStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_CASH_FLOW)) store.unregisterModule(STORE_MODULE_CASH_FLOW)
    })

    const {
      refTable,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      cashierState,
      filterStartDate,
      filterEndDate,
      showOverlay,

      fetch,
      handleAccounts,
      handleDescription,
    } = useTransfer()

    return {
      refTable,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      cashierState,
      filterStartDate,
      filterEndDate,
      showOverlay,

      fetch,
      handleAccounts,
      handleDescription,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
      // resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>
