import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cashFlow: {},
    cashFlows: [],
  },
  getters: {},

  mutations: {
    SET(state, data) {
      state.cashFlow = data
    },
    SET_LIST(state, data) {
      state.cashFlows = data
    },
    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.cashFlows)) state.cashFlows.push(data)
    },
    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.cashFlows)) {
        const idx = state.cashFlows.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.cashFlows, idx, data)
      }
    },
    DELETE_REGISTER(state, payload) {
      const idx = state.cashFlows.data.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.cashFlows.data.splice(idx, 1)
        state.cashFlows.total -= 1
      }
      if (Array.isArray(state.cashFlows)) {
        const idx2 = state.cashFlows.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.cashFlows.splice(idx, 1)
        }
      }
      if (state.cashFlow && state.cashFlow.id === payload) {
        state.cashFlow = { }
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.cashFlows.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/cash-flows${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          if (!args) commit('SET_LIST', resp.data)
          else { commit('SET_LIST', resp.data) }
          return resp
        }
        return state.cashFlows
      } catch (e) {
        return e
      }
    },

    fetchOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/cash-flows/${id}`)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    chargeback(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/cash-flow/chargeback/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
