<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <!-- Modal New Account -->
    <b-modal
      id="modal-new-account"
      v-model="showLocal"
      centered
      size="xl"
      title="Conta"
      hide-footer
      @hidden="hidden"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Tipo de Conta"
              label-for="account-type"
            >
              <v-select
                v-model="account.type"
                :reduce="option => option.value"
                :searchable="false"
                :clearable="false"
                input-id="account-type"
                label="label"
                :options="accountTypes"
                @input="resetFormData(null)"
              >
                <template #no-options="{ }">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Mostrar em"
              label-for="shows-in"
            >
              <v-select
                v-model="account.shows_in"
                input-id="shows-in"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="option => option.value"
                :searchable="false"
                :clearable="false"
                label="label"
                :options="showsInOptions"
                placeholder="NÃO MOSTRAR"
                multiple
              >
                <template #no-options="{ }">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <validation-provider
              #default="validationContext"
              name="Nome"
              rules="required"
              immediate
            >
              <b-form-group
                label="Nome"
                label-for="account-name"
              >
                <b-form-input
                  id="account-name"
                  v-model="account.account"
                  placeholder="Ex.: Cartão de Crédito, etc..."
                  :state="getValidationState(validationContext) ? null : false"
                  trim
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>

          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Saldo"
              label-for="opening-balance"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span>R$</span>
                </b-input-group-prepend>
                <currency-input
                  v-model="account.balance"
                  :disabled="account.id"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <template v-if="account.type !== 'CARD'">
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Juros"
                rules="min_value:0"
                immediate
              >
                <b-form-group
                  label="Juros"
                  label-for="default-interest"
                >
                  <b-input-group>
                    <b-form-input
                      id="default-interest"
                      v-model="account.default_interest"
                      type="number"
                      placeholder="0"
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    />
                    <b-input-group-append
                      is-text
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    >
                      <span>%</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="!!validationContext.errors.length"
                    class="text-danger"
                  >
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Aplicar juros em"
                rules="min_value:1"
                immediate
              >
                <b-form-group
                  label="Aplicar juros em"
                  label-for="default-interest-days"
                >

                  <b-input-group>
                    <b-form-input
                      id="default-interest-days"
                      v-model="account.default_interest_days"
                      type="number"
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    />
                    <b-input-group-append
                      is-text
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    >
                      <span>Dias</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="!!validationContext.errors.length"
                    class="text-danger"
                  >
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Multa"
                rules="min_value:0"
                immediate
              >
                <b-form-group
                  label="Multa"
                  label-for="default-fee"
                >
                  <b-input-group>
                    <b-form-input
                      id="default-fee"
                      v-model="account.default_fee"
                      type="number"
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    />
                    <b-input-group-append
                      is-text
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    >
                      <span>%</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="!!validationContext.errors.length"
                    class="text-danger"
                  >
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Aplicar multa em"
                rules="min_value:1"
                immediate
              >
                <b-form-group
                  label="Aplicar multa em"
                  label-for="fine-date"
                >
                  <b-input-group>
                    <b-form-input
                      id="fine-date"
                      v-model="account.default_fee_days"
                      type="number"
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    />
                    <b-input-group-append
                      is-text
                      :style="!!validationContext.errors.length ? 'border-color: #ea5455': ''"
                    >
                      <span>Dias</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="!!validationContext.errors.length"
                    class="text-danger"
                  >
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </template>
          <!-- Conta tipo Banco -->
          <template v-if="account.type === 'BANK' || account.type === 'CARD'">
            <b-col
              cols="12"
              md="12"
              class="mb-1"
            >
              <span>
                <feather-icon
                  icon="CreditCardIcon"
                  style="margin-bottom: 4px; 4px; margin-right: 8px;"
                />
                Dados Bancários
              </span>
            </b-col>
            <b-col
              cols="12"
              :md="account.type !== 'CARD' ? '10' : '12'"
            >

              <validation-provider
                #default="validationContext"
                name="Banco"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Banco"
                  label-for="bank-name-id"
                  :state="getValidationState(validationContext) ? null : false"
                >

                  <v-select
                    v-model="account.bank_account.bank"
                    input-id="bank-name-id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    clearable
                    :options="banks"
                    placeholder="Selecione"
                    @input="paperEmission = 'no'"
                  >
                    <template #no-options="{ }">
                      Nenhum resultado encontrado, selecione um convênio.
                    </template>
                  </v-select>

                  <small
                    v-show="!getValidationState(validationContext)"
                    class="text-danger"
                  >{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
            <b-col
              v-if="account.type !== 'CARD'"
              cols="12"
              md="2"
            >
              <b-form-group
                label="Emitir Boleto"
                label-for="paper-emission"
              >
                <v-select
                  v-model="paperEmission"
                  :reduce="val => val.value"
                  :searchable="false"
                  :clearable="false"
                  input-id="paper-emission"
                  :options="[
                    { label: 'SIM', value: 'yes' },
                    { label: 'NÃO', value: 'no' },
                  ]"
                  :disabled="!account.bank_account.bank || !account.bank_account.bank.active"
                >
                  <template #no-options="{ }">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >

              <validation-provider
                #default="validationContext"
                name="Agência"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Agência"
                  label-for="bank-agency"
                >
                  <b-form-input
                    id="bank-agency"
                    v-model="account.bank_account.branch_code"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>

            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="DV Agência"
                rules="required"
                immediate
              >
                <b-form-group
                  label="DV Agência"
                  label-for="dv-bank-agency"
                >
                  <b-form-input
                    id="dv-bank-agency"
                    v-model="account.bank_account.branch_dv"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="Conta Corrente"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Conta Corrente"
                  label-for="current-account"
                >
                  <b-form-input
                    id="current-account"
                    v-model="account.bank_account.code"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="DV Conta Corrente"
                rules="required"
                immediate
              >
                <b-form-group
                  label="DV Conta Corrente"
                  label-for="dv-current-account"
                >
                  <b-form-input
                    id="dv-current-account"
                    v-model="account.bank_account.dv"
                    :state="getValidationState(validationContext) ? null : false"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>

            <div
              v-if="account.type === 'CARD'"
              class="pl-1"
              style="width: 50%"
            >

              <b-row
                v-for="(creditCard, idx) in account.bank_account.card_account.card_flags"
                :key="idx"
              >

                <!-- Field: Bandeira do Cartão -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Bandeira do Cartão"
                    rules="required"
                    immediate
                  >
                    <b-form-group
                      label="Bandeira do Cartão"
                      label-for="credit-card-flag"
                      :state="getValidationState(validationContext) ? null : false"
                    >
                      <v-select
                        v-model="creditCard.id"
                        :state="getValidationState(validationContext) ? null : false"
                        :searchable="false"
                        :clearable="false"
                        input-id="credit-card-flag"
                        :reduce="val => val.value"
                        :options="cardFlags"
                        placeholder="Selecione"
                      />
                      <small
                        v-show="!getValidationState(validationContext)"
                        class="text-danger"
                      >{{ validationContext.errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Valor -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Taxa %"
                    rules="required|min_value:0"
                    immediate
                  >
                    <b-form-group
                      label="Taxa %"
                      label-for="credit-cart-tax"
                    >
                      <b-input-group>
                        <b-form-input
                          id="credit-cart-tax"
                          v-model="creditCard.tax"
                          type="number"
                          :state="getValidationState(validationContext) ? null : false"
                        />
                      </b-input-group>
                    </b-form-group>
                    <small
                      v-show="!getValidationState(validationContext)"
                      class="text-danger"
                    >{{ validationContext.errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <!-- Col: Plus / Minus Icons -->
                <b-col
                  cols="12"
                  md="2"
                >
                  <feather-icon
                    v-if="idx == 0"
                    class="mt-2 ml-1 cursor-pointer text-success"
                    icon="PlusCircleIcon"
                    size="28"
                    @click="addCreditCard"
                  />

                  <feather-icon
                    v-else
                    class="mt-2 ml-1 cursor-pointer text-danger"
                    icon="MinusCircleIcon"
                    size="28"
                    @click="removeCreditCard(idx)"
                  />
                </b-col>

              </b-row>

            </div>

            <template v-if="paperEmission === 'yes'">
              <b-col
                cols="12"
                md="12"
                class="mb-1"
              >
                <span>
                  <feather-icon
                    icon="MenuIcon"
                    style="margin-bottom: 4px; margin-right: 8px;"
                  />
                  Configuração de Boletos
                </span>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Espécie de Documento"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Espécie de Documento"
                    label-for="doc-type"
                  >
                    <b-form-input
                      id="doc-type"
                      v-model="account.bank_account.slip_config.especie_documento"
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>

                </validation-provider>

              </b-col>
              <b-col
                cols="12"
                md="2"
              >

                <validation-provider
                  #default="validationContext"
                  name="Convênio"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Convênio"
                    label-for="covenant"
                  >
                    <b-form-input
                      id="covenant"
                      v-model="account.bank_account.slip_config.convenio"
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>

                </validation-provider>

              </b-col>
              <b-col
                cols="12"
                md="2"
              >

                <validation-provider
                  #default="validationContext"
                  name="Carteira"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Carteira"
                    label-for="wallet"
                  >
                    <b-form-input
                      id="wallet"
                      v-model="account.bank_account.slip_config.carteira"
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>

                </validation-provider>

              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Variação Carteira"
                  label-for="wallet-var"
                >
                  <b-form-input
                    id="wallet-var"
                    v-model="account.bank_account.slip_config.variacao_carteira"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Impressão"
                  label-for="print-type"
                >
                  <v-select
                    v-model="account.bank_account.slip_config.printing_type"
                    input-id="print-type"
                    :reduce="val => val.value"
                    :clearable="false"
                    :searchable="false"
                    :options="[
                      {label: 'A4', value: 'normal'},
                      {label: 'Carnê', value: 'carne'}
                    ]"
                  >
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Nosso nº Inicial"
                  label-for="our-first-number"
                >
                  <b-form-input
                    id="our-first-number"
                    v-model="account.bank_account.slip_config.next_number"
                    value="1"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Nº Inicial Remessa"
                  label-for="first-number"
                >
                  <b-form-input
                    id="first-number"
                    v-model="account.bank_account.slip_config.remessa_next_number"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Posto"
                  label-for="position"
                >
                  <b-form-input
                    id="position"
                    v-model="account.bank_account.slip_config.posto"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="CNAB"
                  label-for="cnab"
                >
                  <v-select
                    v-model="account.bank_account.slip_config.cnab"
                    input-id="cnab"
                    :options="[240, 400]"
                    :clearable="false"
                    :searchable="false"
                  >
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Byte"
                  label-for="byte"
                >
                  <b-form-input
                    id="byte"
                    v-model="account.bank_account.slip_config.byte"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Local de Pagamento"
                  label-for="payment-address"
                >
                  <b-form-input
                    id="payment-address"
                    v-model="account.bank_account.slip_config.payment_place"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Taxa por Boleto"
                  label-for="slip-tax"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <span>R$</span>
                    </b-input-group-prepend>
                    <currency-input
                      id="slip-tax"
                      v-model="account.bank_account.slip_config.tax"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Aplicar Taxa"
                  label-for="tax-apply"
                >
                  <v-select
                    v-model="account.bank_account.slip_config.tax_apply"
                    :reduce="val => val.value"
                    :searchable="false"
                    :clearable="false"
                    input-id="tax-apply"
                    label="label"
                    :options="[
                      { label: 'Na baixa', value: 'on_payment' },
                      { label: 'No Lançamento', value: 'on_register' }
                    ]"
                  >
                    <template #no-options="{ }">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col
                v-for="(demDesc, i) in account.bank_account.slip_config.demonstrative_descriptions"
                :key="`${i}_demonstrative_description`"
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="`Descrição do demonstrativo linha ${i+1}`"
                  :label-for="`demo-details-${i+1}`"
                >
                  <b-form-input
                    :id="`demo-details-${i+1}`"
                    v-model="demDesc.demonstrative_description"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-for="(instruction, i) in account.bank_account.slip_config.instructions"
                :key="`${i}_instruction`"
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="`Instruções linha ${i+1}`"
                  :label-for="`instructions-${i+1}`"
                >
                  <b-form-input
                    :id="`instructions-${i+1}`"
                    v-model="instruction.instruction"
                  />
                </b-form-group>
              </b-col>
            </template>
          </template>
        </b-row>

        <hr>

        <!-- Footer -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          />

          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="outline-secondary"
              block
              @click="hidden"
            >
              Cancelar
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              :disabled="invalid"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
  BForm, BFormGroup, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  ref, computed, onUnmounted, watch, toRefs,
} from '@vue/composition-api'
import useStoreCardFlag from '@/store/card-flags/index'
import store from '@/store'

import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'
import usePosition from './usePosition'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    CurrencyInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    editAccount: {
      type: Object,
      default: () => null,
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages

    const USER_APP_STORE_MODULE_NAME = 'store-card-flag'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, useStoreCardFlag)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const { show, editAccount } = toRefs(props)

    const modalHandleShow = ref(false)
    const paperEmission = ref('no')
    const showLocal = ref(false)

    const blankAccount = {
      id: null,
      account: null,
      shows_in: [
        'TO_PAY',
        'TO_RECEIVE',
        'CASH_FLOW',
        'TO_TRANSFER',
      ],
      type: 'SIMPLE',
      balance: 0,
      default_interest: 0,
      default_interest_type: '%',
      default_interest_days: 1,
      default_fee: 0,
      default_fee_type: '%',
      default_fee_days: 1,
      bank_account: {
        id: null,
        bank: null,
        code: null, // código conta
        dv: null,
        type: null, // tipo (por exemplo, na caixa tem PF, PJ, POUPANÇA, 003, 001, etc...) nullable
        branch_code: null, // código agência
        branch_dv: null, // digito verificador agência nullable
        bank_id: null,
        slip_config: {
          identification: null,
          especie_documento: null,
          convenio: null,
          carteira: null,
          variacao_carteira: null, // BB remessas
          printing_type: 'carne',
          posto: null, // utilizado pela SICREDI
          byte: null, // prefixo do nosso número, utilizado pela SICREDI
          cnab: 240,
          payment_place: null, // Local de pagamento
          demonstrative_descriptions: [
            { demonstrative_description: null },
            { demonstrative_description: null },
            { demonstrative_description: null },
          ],
          instructions: [
            { instruction: null },
            { instruction: null },
            { instruction: null },
          ],
          nao_receber_apos: 0, // Nao receber após x dias. Zero sem data de bloqueio
          bank_account_id: null, // Conta que possui agencia e banco
          tax: 0, // valor da taxa (custo) do boleto
          tax_apply: 'on_register', // Quando aplicar (ao gerar ou ao pagar/baixar o boleto) on_payment | on_register
        },
        card_account: {
          card_flags: [{
            id: null,
            tax: 0,
          }],
        },
      },
    }

    store.dispatch('banks/fetch')
    const banks = computed(() => store.getters['banks/getToSelect'])
    const account = ref(JSON.parse(JSON.stringify(blankAccount)))

    watch(show, newValue => {
      showLocal.value = newValue
      if (editAccount.value && editAccount.value.id) {
        if (editAccount.value.bank_account) {
          editAccount.value.bank_account.bank = banks.value.filter(el => el.value === editAccount.value.bank_account.bank_id)
          if (editAccount.value.bank_account.card_account) {
            editAccount.value.bank_account.card_account.card_flags = editAccount.value.bank_account.card_account.card_flags.map(e => ({
              id: e.id,
              tax: e.pivot.tax,
              tax_type: e.pivot.tax_type,
              type_day_receipt: e.pivot.type_day_receipt,
              day_receipt: e.pivot.day_receipt,
            }))
          }
        }
      }
      account.value = JSON.parse(JSON.stringify(editAccount.value || blankAccount))
    })

    const resetFormData = t => {
      const tmpBlankAccount = JSON.parse(JSON.stringify(blankAccount))
      tmpBlankAccount.type = t || account.value.type
      if (account.value.id) tmpBlankAccount.id = account.value.id
      paperEmission.value = 'no'
      account.value = tmpBlankAccount
    }

    const hidden = () => {
      resetFormData('SIMPLE')
      emit('hidden')
    }

    const save = async () => {
      emit('save', account.value)
      hidden()
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetFormData)

    const fillData = data => {
      account.value = data
    }

    const {
      refetchData,
      deleteData,
      accountTypes,
      showsInOptions,
    } = usePosition()

    store.dispatch('store-card-flag/fetch')
    const cardFlags = computed(() => store.getters['store-card-flag/getToSelect'])

    const addCreditCard = () => {
      account.value.bank_account.card_account.card_flags.push({
        id: '',
        tax: '',
      })
    }

    const removeCreditCard = idx => {
      account.value.bank_account.card_account.card_flags.splice(idx, 1)
    }

    return {
      required,
      modalHandleShow,
      paperEmission,
      showLocal,
      deleteData,
      refFormObserver,
      account,
      banks,
      cardFlags,
      accountTypes,
      showsInOptions,

      fillData,
      refetchData,
      getValidationState,
      resetFormData,
      addCreditCard,
      removeCreditCard,
      hidden,
      save,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
