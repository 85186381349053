import { ref, watch, computed } from '@vue/composition-api'
import { dateTime } from '@core/utils/filter'

import store from '@/store'

export default function useCashierBulletin(root, emit) {
  const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
    root.$swal({
      icon,
      title,
      text,
      customClass: {
        confirmButton,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
    else if (e.response.status === 401) showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
    else showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
  }

  const refTable = ref(null)
  const tableColumns = [
    {
      key: 'created_at',
      label: 'Data',
      formatter: value => dateTime(value, 'dateTimeNoSecond'),
      tdClass: 'created_atTdClass',
      sortable: true,
    },
    {
      key: 'id',
      label: 'Código',
      tdClass: 'idTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'descrição',
      tdClass: 'descriptionTdClass',
      sortable: true,
    },
    {
      key: 'accounts',
      label: 'Contas',
      tdClass: 'accountsTdClass',
      sortable: true,
    },
    {
      key: 'cashIn',
      label: 'Entrada',
      tdClass: 'cashInTdClass',
      sortable: true,
    },
    {
      key: 'cashOut',
      label: 'Saída',
      tdClass: 'cashOutTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const transferShowModal = ref(false)
  const detailsShowModal = ref(false)
  const showLoading = ref(false)
  const movement = ref({})
  const showOverlay = ref(false)

  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const formatDate = dte => `${dte.getFullYear()}-${(dte.getMonth() + 1) < 10 ? `0${dte.getMonth() + 1}` : (dte.getMonth() + 1)}-${dte.getDate() < 10 ? `0${dte.getDate()}` : dte.getDate()}`

  const filterStartDate = ref(formatDate(firstDay))
  const filterEndDate = ref(formatDate(lastDay))

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const getLastCashier = async () => {
    showLoading.value = true
    await store.dispatch('store-general-cashier/getLast')
    showLoading.value = false
  }
  getLastCashier()

  const generalCashier = computed(() => store.getters['store-general-cashier/getGeneralCashier'])

  const closeCashier = async () => {
    try {
      showLoading.value = true
      await store.dispatch('store-general-cashier/handleStatus', 'CLOSED')
      showToast('success', 'Sucesso', 'Caixa Fechado com Sucesso.')
      showLoading.value = false
    } catch (e) {
      showLoading.value = false
      catchErr(e)
    }
  }
  const openCashier = async () => {
    try {
      showLoading.value = true
      await store.dispatch('store-general-cashier/handleStatus', 'OPEN')
      showToast('success', 'Sucesso', 'Caixa Aberto com Sucesso.')
      showLoading.value = false
    } catch (e) {
      showLoading.value = false
      catchErr(e)
    }
  }

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterStartDate, filterEndDate], () => {
    refetchData()
  })

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-cash-flow/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        type: ['CREDIT', 'DEBIT', 'CHARGEBACK'],
        filterStartDate: filterStartDate.value,
        filterEndDate: filterEndDate.value,
      })
      .then(response => {
        showOverlay.value = false
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(() => {
        showOverlay.value = false
        showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
      })
  }

  const handleAccounts = accounts => {
    const accs = accounts.filter(e => e.pivot.amount > 0)
    return accs.map(e => ({
      account: e.account,
      amount: e.pivot.amount,
      details: JSON.parse(e.pivot.details),
    }))
  }

  const handleDescription = description => description.split('<br>')

  const handleDetailsShowModal = data => {
    detailsShowModal.value = true
    movement.value = data
  }

  const saveTransfer = async data => {
    try {
      if (data.from === data.to) {
        showToast('warning', 'Atenção', 'Selecione contas diferentes', 'btn btn-warning')
        return
      }
      await store.dispatch('store-general-cashier/saveTransfer', data)
      refetchData()
      emit('refetchDataTransfer')
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const handleChargeback = data => {
    root.$swal({
      title: 'Deseja estornar o registro?',
      text: 'Qual o motivo do estorno?',
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo aqui...',
      inputAttributes: {
        'aria-label': 'Descreva o motivo aqui',
      },
      // eslint-disable-next-line consistent-return
      inputValidator: value => {
        if (!value) {
          return 'Este campo é obrigatório!'
        }
      },
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        try {
          // eslint-disable-next-line no-param-reassign
          data.reason = result.value
          await store.dispatch('store-cash-flow/chargeback', data)
          refetchData()
          emit('refetchData')
          showToast('success', 'Sucesso', 'Movimentação estornada.')
        } catch (e) {
          catchErr(e)
        }
      }
    })
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTable,
    generalCashier,
    transferShowModal,
    filterStartDate,
    filterEndDate,
    detailsShowModal,
    movement,
    showLoading,
    showOverlay,

    handleDetailsShowModal,
    closeCashier,
    openCashier,
    fetch,
    handleAccounts,
    handleDescription,
    saveTransfer,
    handleChargeback,
  }
}
